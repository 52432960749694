<template>
  <main>
    <div class="banner">
      <div class="wrapper">
        <h1 class="h1">司普科技，驱动数智升级</h1>
        <h2 class="h2">垂直行业解决方案赋能你我的工作和生活</h2>
        <n-button type="primary" size="tiny" class="btn rounded"
          >了解详情</n-button
        >
      </div>
    </div>
    <div class="body-wrapper">
      <div class="body">
        <div class="core-product" style="display: none">
          <h3 class="title text-center">核心产品</h3>
          <small class="desc block text-sm text-center"
            >一站式AI智能文本处理和数据服务</small
          >
          <ul class="core-product-body flex justify-between">
            <li
              v-for="(item, index) in coreProducts"
              :key="index"
              class="body-item"
            >
              <h4 class="body-title">{{ item.title }}</h4>
              <small class="body-desc block">{{ item.desc }}</small>
            </li>
            <li class="body-item vitual"></li>
          </ul>
        </div>
        <div class="solve-ways">
          <h3 class="title text-center">行业解决方案</h3>
          <small class="desc block text-sm text-center"
            >智能影像识别文本块，精准定位</small
          >
          <ul class="solve-ways-body">
            <li
              v-for="item in solveWays"
              :key="item.title"
              class="body-item text-center"
            >
              {{ item.title }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup lang="ts">
const { coreProducts, solveWays } = useConfig()
</script>

<style scoped lang="scss">
.banner {
  height: 348px;
  padding-top: 98px;
  padding-left: 222px;
  box-sizing: border-box;
  background: url('@/assets/img/bg.png') no-repeat #dae6fc;
  .wrapper {
    width: 662px;
    text-align: center;
  }
  .h1 {
    font-size: 48px;
  }
  .h2 {
    font-size: 20px;
    font-weight: 500;
    color: rgba(47, 52, 67, 0.48);
    margin-top: 7px;
  }
  .btn {
    width: 90px;
    height: 28px;
    margin-top: 8px;
  }
}
.body-wrapper {
  .body {
    width: 1020px;
    margin: auto;
    padding-top: 70px;
    padding-bottom: 125px;
  }
  title {
    font-size: 24px;
  }
  .desc {
    color: var(--text-note);
  }
  .core-product {
    .core-product-body {
      flex-wrap: wrap;
      .body-item {
        border-radius: 2px;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.09);
        width: 324px;
        margin-top: 24px;
      }
      .body-item.vitual {
        height: 0;
      }
      .body-title {
        font-size: 18px;
        padding: 20px 30px;
      }
      .body-desc {
        padding: 16px 30px;
        color: var(--text-desc);
      }
    }
  }
  .solve-ways {
    margin-top: 106px;
    .solve-ways-body {
      display: grid;
      grid-template-columns: repeat(3, 340px);
      grid-gap: 1px;
      grid-template-rows: repeat(2, 144px);
      color: white;
      font-size: 18px;
      .body-item {
        background-color: #184aaa;
        line-height: 144px;
      }
    }
  }
}
</style>
